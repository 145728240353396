import React, { FC, useEffect } from 'react';
import _ from 'lodash';
import './SelectWithNumbers.css';
import minus from '../../Assets/Icon awesome-minus.svg';
import plus from '../../Assets/Icon awesome-plus.svg';

interface SelectWithNumbersProps {
  label: any;
  value: number;
  onChange: (data: any) => void;
  maxValue: number;
  disabled?: boolean;
  defaultValue?: number;
}

export const SelectWithNumbers: FC<SelectWithNumbersProps> = ({
  label,
  value,
  onChange,
  maxValue,
  disabled = false,
  defaultValue
}) => {
  const [quantity, setQuantity] = React.useState(0);
  useEffect(() => {
    if (value !== 0) {
      setQuantity(value);
    }
  }, [value]);
  const onChangeByButton = (data: number) => {
    value = value + data;
    if (value <= 0) {
      value = value - data;
      return;
    }
    setQuantity(quantity);
    onChange(value);
  };
  const onChangeByInput = (number: any) => {
    setQuantity(number);
  };
  const restoreValue = (number: any) => {
    if (_.isEmpty(number) || number <= 0 || quantity > maxValue) {
      setQuantity(value);
      onChange(value);
    } else {
      value = number;
      setQuantity(value);
      onChange(value);
    }
  };
  const isQuantityChangeable = () => {
    return quantity < maxValue && !disabled;
  };
  return (
    <div className={'cart-item-qty'}>
      <div className={'cart-listing-item-qty'}>
        <p className={'m-0 cart-listing-item-qty-label mx-2'}>{`${label}: `}</p>
        <div className={'quantity-grid-layout'}>
          <div
            className={`quantity-input-decrement ${
              disabled || quantity === 1 ? 'disabled' : ''
            }`}
            onClick={() => !disabled && onChangeByButton(-1)}
          >
            <img src={minus} alt={'minus'} />
          </div>
          <div className={'quantity-input-field '}>
            <input
              type={'number'}
              style={{ width: '100%', textAlign: 'center' }}
              defaultValue={quantity}
              value={quantity}
              disabled={disabled}
              className={'quantity-input'}
              onChange={e => onChangeByInput(e.target.value)}
              onBlur={e => restoreValue(e.target.value)}
            />
          </div>
          <div
            className={`quantity-input-increment ${disabled ? 'disabled' : ''}`}
            aria-disabled={!isQuantityChangeable()}
            onClick={() => isQuantityChangeable() && onChangeByButton(1)}
          >
            <img src={plus} alt={'plus'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectWithNumbers;
